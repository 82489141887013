export const LoveIcon = () => {
    return (
        <div>
            <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g
                    id="控件"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <g id="评论/点赞" stroke="#828387" stroke-width="2">
                        <g id="编组">
                            <path
                                d="M16.3667196,5.00154561 C17.4245684,5.00215572 18.4815783,5.40669448 19.2874332,6.21441069 C20.0707444,6.99953099 20.4749039,8.01986308 20.499789,9.04770023 C20.5246219,10.0733823 20.1721086,11.1066937 19.4414644,11.9209207 L19.4414644,11.9209207 L12.3569259,19.0301895 L4.71256676,12.083677 C3.90351184,11.2727534 3.49898438,10.2108986 3.49898438,9.14904382 C3.49898438,8.08718904 3.90351184,7.02533425 4.71256676,6.21441069 C5.49266979,5.43250589 6.50810888,5.02836123 7.53148149,5.00236292 C8.55300732,4.97641153 9.582595,5.32690031 10.3936303,6.05488288 L10.3936303,6.05488288 L12.0035388,7.65220921 L13.4407618,6.21185343 C14.2501751,5.40430336 15.3087969,5.00093545 16.3667196,5.00154561 Z"
                                id="路径"
                            ></path>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
};
export const ReplyIcon = () => {
    return (
        <div>
            <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g
                    id="控件"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <g id="评论/回复" stroke="#828387" stroke-width="2">
                        <g id="编组" transform="translate(3.503068, 5.000000)">
                            <path
                                d="M15.9969316,1 L15.9969316,12 L10.8761996,12 L8.49832702,14.5363974 L6.11726667,12 L1,12 L1,1 L15.9969316,1 Z"
                                id="矩形"
                            ></path>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
};
export const DeleteIcon = () => {
    return (
        <div>
            <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g
                    id="控件"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <g id="评论/删除" fill="#828387" fill-rule="nonzero">
                        <g id="编组" transform="translate(4.000000, 3.500000)">
                            <path
                                d="M15.5,1.5 C15.7761424,1.5 16,1.72385763 16,2 L16,3 C16,3.27614237 15.7761424,3.5 15.5,3.5 L14.844,3.5 L13.9409532,15.1163154 C13.8802122,15.8972712 13.2287843,16.5 12.4454698,16.5 L3.5545302,16.5 C2.77121575,16.5 2.11978776,15.8972712 2.05904675,15.1163154 L1.156,3.5 L0.5,3.5 C0.223857625,3.5 3.38176876e-17,3.27614237 0,3 L0,2 C-3.38176876e-17,1.72385763 0.223857625,1.5 0.5,1.5 L4.4,1.5 L4.87427814,0.314304662 C4.95020981,0.124475472 5.13406419,3.75572772e-17 5.33851648,0 L10.6614835,0 C10.8659358,-5.37157638e-16 11.0497902,0.124475472 11.1257219,0.314304662 L11.599,1.5 L15.5,1.5 Z M12.838,3.5 L3.161,3.5 L4.017,14.5 L11.982,14.5 L12.838,3.5 Z"
                                id="形状结合"
                            ></path>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
};
