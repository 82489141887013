import React from 'react';

export const ColectIcon = () => {
    return (
        <>
            <svg
                width="48px"
                height="48px"
                viewBox="0 0 60 60"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g
                    id="Btn/Add/Normal"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <circle
                        id="bg"
                        // fill-opacity="0.8"
                        fill="#272727"
                        cx="30"
                        cy="30"
                        r="30"
                    ></circle>
                    <path
                        d="M29.3055556,17.25 C29.6890866,17.25 30,17.5609134 30,17.9444444 L30,19.3888889 C30,19.77242 29.6890866,20.0833333 29.3055556,20.0833333 L22.9166667,20.0833333 L22.9166667,39.724 L28.6396082,34.9562398 C29.3713667,34.346441 30.4106369,34.302884 31.1863257,34.8255686 L31.3603918,34.9562398 L37.0833333,39.7254167 L37.0833333,33.5277778 C37.0833333,33.1442467 37.3942467,32.8333333 37.7777778,32.8333333 L39.2222222,32.8333333 C39.6057533,32.8333333 39.9166667,33.1442467 39.9166667,33.5277778 L39.9166667,41.2376789 C39.9166667,42.4112764 38.9652794,43.3627321 37.7916667,43.3627321 C37.3655561,43.3627321 36.9510168,43.2346313 36.6007867,42.9976358 L36.4312748,42.8701491 L30,37.50975 L23.5687252,42.8701491 C22.7234861,43.574515 21.4929682,43.5114751 20.7233835,42.7579578 L20.5758631,42.5980707 C20.3030814,42.2707327 20.1360669,41.8703014 20.0939154,41.4495208 L20.0833333,41.2376789 L20.0833333,20.0833333 C20.0833333,18.5896541 21.2391602,17.3659327 22.7052117,17.2577715 L22.9166667,17.25 L29.3055556,17.25 Z M39.2222222,17.25 C39.6057533,17.25 39.9166667,17.5609134 39.9166667,17.9444444 L39.9163333,21.499 L43.4722222,21.5 C43.8557533,21.5 44.1666667,21.8109134 44.1666667,22.1944444 L44.1666667,23.6388889 C44.1666667,24.02242 43.8557533,24.3333333 43.4722222,24.3333333 L39.9163333,24.333 L39.9166667,27.8888889 C39.9166667,28.27242 39.6057533,28.5833333 39.2222222,28.5833333 L37.7777778,28.5833333 C37.3942467,28.5833333 37.0833333,28.27242 37.0833333,27.8888889 L37.0823333,24.333 L33.5277778,24.3333333 C33.1442467,24.3333333 32.8333333,24.02242 32.8333333,23.6388889 L32.8333333,22.1944444 C32.8333333,21.8109134 33.1442467,21.5 33.5277778,21.5 L37.0823333,21.499 L37.0833333,17.9444444 C37.0833333,17.5609134 37.3942467,17.25 37.7777778,17.25 L39.2222222,17.25 Z"
                        fill="#FFF"
                        fillRule="nonzero"
                    ></path>
                </g>
            </svg>
        </>
    );
};
export const ColectedIcon = () => {
    return (
        <>
            <svg
                width="48px"
                height="48px"
                viewBox="0 0 60 60"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g
                    id="Btn/Added/Normal"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <circle
                        id="bg"
                        // fill-opacity="0.8"
                        fill="#272727"
                        opacity="0.800000012"
                        cx="30"
                        cy="30"
                        r="30"
                    ></circle>
                    <path
                        d="M29.3055556,17.25 C29.6890866,17.25 30,17.5609134 30,17.9444444 L30,19.3888889 C30,19.77242 29.6890866,20.0833333 29.3055556,20.0833333 L22.9166667,20.0833333 L22.9166667,39.724 L28.6396082,34.9562398 C29.3713667,34.346441 30.4106369,34.302884 31.1863257,34.8255686 L31.3603918,34.9562398 L37.0833333,39.7254167 L37.0833333,33.5277778 C37.0833333,33.1442467 37.3942467,32.8333333 37.7777778,32.8333333 L39.2222222,32.8333333 C39.6057533,32.8333333 39.9166667,33.1442467 39.9166667,33.5277778 L39.9166667,41.2376789 C39.9166667,42.4112764 38.9652794,43.3627321 37.7916667,43.3627321 C37.3655561,43.3627321 36.9510168,43.2346313 36.6007867,42.9976358 L36.4312748,42.8701491 L30,37.50975 L23.5687252,42.8701491 C22.7234861,43.574515 21.4929682,43.5114751 20.7233835,42.7579578 L20.5758631,42.5980707 C20.3030814,42.2707327 20.1360669,41.8703014 20.0939154,41.4495208 L20.0833333,41.2376789 L20.0833333,20.0833333 C20.0833333,18.5896541 21.2391602,17.3659327 22.7052117,17.2577715 L22.9166667,17.25 L29.3055556,17.25 Z M44.8295835,16.9823733 L45.85096,18.0037498 C46.1221574,18.2749472 46.1221574,18.7146451 45.85096,18.9858426 L38.8191759,26.0176267 C38.5924087,26.2443939 38.2478292,26.2815451 37.9824597,26.1290803 C37.7719503,26.1592923 37.5490181,26.094154 37.3865555,25.9316914 L33.3599753,21.9051111 C33.0887778,21.6339137 33.0887778,21.1942158 33.3599753,20.9230184 L34.3813517,19.9016419 C34.6525491,19.6304445 35.092247,19.6304445 35.3634445,19.9016419 L38.1453301,22.6837282 L43.8474908,16.9823733 C44.1186882,16.7111759 44.5583861,16.7111759 44.8295835,16.9823733 Z"
                        fill="#FFF"
                        fillRule="nonzero"
                    ></path>
                </g>
            </svg>
        </>
    );
};
