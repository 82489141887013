export const Paypal = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 8C0 3.58172 3.58172 0 8 0H64C68.4183 0 72 3.58172 72 8V64C72 68.4183 68.4183 72 64 72H8C3.58172 72 0 68.4183 0 64V8Z"
                fill="#237FB5"
            ></path>

            <g
                transform="translate(10 6)"
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <path
                    d="M25.5466667,57.5484848 L27.3593939,46.0539394 L27.4763636,45.4169697 C27.5636364,44.8630303 27.8472727,44.3563636 28.2745455,43.9915152 C28.7018182,43.6266667 29.2466667,43.4254545 29.8084848,43.4254545 L31.2478788,43.4254545 C33.6472727,43.4254545 35.8515152,43.1690909 37.8,42.6636364 C39.8787879,42.1248485 41.7278788,41.2854545 43.2951515,40.1709091 C44.9569697,38.9890909 46.3527273,37.4612121 47.4442424,35.6309091 C48.5957576,33.7 49.4412121,31.3751515 49.9575758,28.7218182 C50.4139394,26.3787879 50.4993939,24.2812121 50.2127273,22.4866667 C49.9090909,20.5909091 49.1787879,18.9733333 48.0424242,17.6775758 C47.3533333,16.8927273 46.4715152,16.2127273 45.4218182,15.6563636 L45.3969697,15.6430303 L45.3963636,15.6127273 C45.7630303,13.2739394 45.7490909,11.3230303 45.3545455,9.64363636 C44.9581818,7.96060606 44.1557576,6.44606061 42.8993939,5.01454545 C40.2963636,2.04848485 35.5618182,0.544242424 28.8278788,0.544242424 L10.3339394,0.544242424 C9.71575758,0.544242424 9.11575758,0.765454545 8.64545455,1.16727273 C8.17515152,1.56909091 7.86363636,2.12727273 7.76666667,2.73818182 L0.0660606061,51.570303 C-0.00424242424,52.0157576 0.123636364,52.4684848 0.416969697,52.810303 C0.71030303,53.1539394 1.1369697,53.3509091 1.58787879,53.3509091 L13.0612121,53.3509091 L13.0509091,53.4054545 L12.2630303,58.4018182 C12.2024242,58.790303 12.3139394,59.1842424 12.5690909,59.4824242 C12.8242424,59.7812121 13.1957576,59.9533333 13.5884848,59.9533333 L23.2115152,59.9533333 C23.7515152,59.9533333 24.2745455,59.76 24.6848485,59.4090909 C25.0951515,59.0581818 25.3678788,58.5715152 25.4521212,58.0387879 L25.5466667,57.5484848"
                    id="Fill-8"
                    fill="#27346A"
                />
                <path
                    d="M18.9539394,15.6763636 C19.0757576,14.9024242 19.5721212,14.2690909 20.2406061,13.9484848 C20.5448485,13.8030303 20.8842424,13.7218182 21.24,13.7218182 L35.7363636,13.7218182 C37.4539394,13.7218182 39.0551515,13.8345455 40.5193939,14.070303 C40.9375758,14.1375758 41.3442424,14.2151515 41.74,14.3030303 C42.1357576,14.3909091 42.5193939,14.4884848 42.8909091,14.5975758 C43.0769697,14.6515152 43.2593939,14.7084848 43.4393939,14.7684848 C44.1581818,15.0072727 44.8272727,15.2884848 45.4430303,15.6145455 C46.1690909,10.9860606 45.4381818,7.83636364 42.9345455,4.98363636 C40.1781818,1.8430303 35.1987879,0.497575758 28.8278788,0.497575758 L10.3339394,0.497575758 C9.03212121,0.497575758 7.92363636,1.44484848 7.72060606,2.73090909 L0.02,51.5630303 C-0.132121212,52.6648485 0.513939394,53.7272727 1.43575758,54.3393939 C2.35757576,54.9515152 3.54545455,54.9824242 4.49090909,54.4193939 L18.9539394,15.6763636 Z"
                    id="Fill-9"
                    fill="#27346A"
                />
                <path
                    d="M45.4430303,15.6145455 L45.4430303,15.6145455 C45.3878788,15.9678788 45.3266667,16.3272727 45.2539394,16.6975758 C42.8157576,29.2193939 34.4715152,33.5484848 23.8145455,33.5484848 L18.3878788,33.5484848 C17.0860606,33.5484848 15.9866667,34.4963636 15.7836364,35.7824242 L12.2169697,58.3951515 C12.0842424,59.2375758 12.7357576,60 13.5884848,60 L23.2121212,60 C24.3509091,60 25.3206061,59.1715152 25.4981818,58.0460606 L25.5927273,57.5569697 L27.4054545,46.0612121 L27.5224242,45.4260606 C27.7,44.3006061 28.669697,43.4721212 29.8084848,43.4721212 L31.2478788,43.4721212 C40.5709091,43.4721212 47.8709091,39.6842424 50.0042424,28.730303 C50.8951515,24.1533333 50.4345455,20.3327273 48.0775758,17.6466667 C47.3648485,16.8345455 46.4775758,16.1624242 45.4430303,15.6145455"
                    id="Fill-11"
                    fill="#94c0dc"
                />
                <path
                    d="M42.8909091,14.5975758 C42.5193939,14.4884848 42.1357576,14.3909091 41.74,14.3030303 C41.3442424,14.2151515 40.9375758,14.1375758 40.5193939,14.070303 C39.0551515,13.8345455 37.4539394,13.7218182 35.7363636,13.7218182 L21.24,13.7218182 C20.8842424,13.7218182 20.5448485,13.8030303 20.2406061,13.9484848 C19.5721212,14.2690909 19.0757576,14.9024242 18.9539394,15.6763636 L15.8727273,35.2121212 L15.7836364,35.7824242 C15.9866667,34.4963636 17.0860606,33.5484848 18.3878788,33.5484848 L23.8145455,33.5484848 C34.4715152,33.5484848 42.8157576,29.2193939 45.2539394,16.6975758 C45.3266667,16.3272727 45.3878788,15.9678788 45.4430303,15.6145455 C44.8272727,15.2884848 44.1581818,15.0072727 43.4393939,14.7684848 C43.2593939,14.7084848 43.0769697,14.6515152 42.8909091,14.5975758"
                    id="Fill-12"
                    fill="#1F264F"
                />
            </g>
        </svg>
    );
};
